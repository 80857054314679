import { Injectable, Inject, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Constants } from '../shared/constants';
import { StorageService } from '../shared/services/storage.service';
import { CartService } from '../cart/cart.service';
import { NotificationService } from '../shared/services/notification.service';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { LoaderService } from '../shared/services/loader.service';
import { DOCUMENT } from '@angular/common';
import { LoginService } from '../login/login.service';
import { GoogleAnalyticsService } from '../shared/services/google-analytics.service';
import { BingAnalyticsService } from '../shared/services/bing-analytics.service';

declare const window: any;
@Injectable({
  providedIn: 'root'
})

export class CheckoutService implements OnDestroy {
  basicAuth: string = btoa(`${Constants.paypal.payPalClientID}:${Constants.paypal.payPalSecret}`);
  isLoggedIn: boolean;
  userSub: Subscription;
  private renderer2: Renderer2;
  private zipCodeErrorMessage = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private cartService: CartService,
    private notificationService: NotificationService,
    @Inject(DOCUMENT) private document,
    private googleAnalyticsService: GoogleAnalyticsService,
    private rendererFactory: RendererFactory2,
    private bingAnalyticsService: BingAnalyticsService,
    private router: Router) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
    this.userSub = this.loginService.user.subscribe(user => {
      if (!!user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }
  setErrorMessage(message: string) {
    this.zipCodeErrorMessage.next(message);
  }

  getErrorMessage() {
    return this.zipCodeErrorMessage.asObservable();
  }

  private orderSummaryData = new BehaviorSubject<any>({});
  orderSummaryData$ = this.orderSummaryData.asObservable();

  private customerOrderData = new BehaviorSubject<any>({});
  customerOrderData$ = this.customerOrderData.asObservable();

  private orderObj = new BehaviorSubject<any>({});
  orderObj$ = this.orderObj.asObservable();

  private updateCart = new BehaviorSubject<boolean>(false);
  updateCart$ = this.updateCart.asObservable();

  pushOrderSummary(orderSummary: any) {
    this.orderSummaryData.next(orderSummary);
  }

  pushCustomerOrderDetails(customerOrder: any) {
    this.customerOrderData.next(customerOrder);
  }

  pushOrderObjData(orderObj: any) {
    this.orderObj.next(orderObj);
  }

  updateCartAfterLogin(isUpdate: boolean) {
    this.updateCart.next(isUpdate);
  }

  getOrderSummary() {
    let orderSummary = this.orderSummaryData.getValue();
    if (orderSummary.length) {
      return orderSummary;
    }
    orderSummary = this.storageService.sessionGetItem('orderSummary');
    if (orderSummary) {
      this.pushOrderSummary(orderSummary);
    }
    return orderSummary;
  }

  getCustomerOrderDetails() {
    let customerOrder = this.customerOrderData.getValue();
    if (customerOrder.length) {
      return customerOrder;
    }
    customerOrder = this.storageService.sessionGetItem('customerOrder');
    if (customerOrder) {
      this.pushOrderSummary(customerOrder);
    }
    return customerOrder;
  }

  buildCustomerOrderSummary(orderSummary: any, subTotal: number, existingShippingMethod: string = orderSummary.shippingMethod): any {
    if (orderSummary && orderSummary.content) {
      orderSummary.content.forEach(element => {
        // Calculate Transit Days
        // Edit 30/12/2019 - Added earliestDeliveryDate & latestDeliveryDate, removed logic of transitDays
        element.shipDate1 = element.earliestDeliveryDate;
        element.shipDate2 = element.latestDeliveryDate;

        if (existingShippingMethod && existingShippingMethod === element.shippingMethod) {
          if (existingShippingMethod === 'In-store Pickup') {
            orderSummary.estimatedTotal = (+subTotal -
              +element.hazmatHandlingFee - +element.shippingTotal).toFixed(2);
          } else {
            // Apply Shipping charges as per Promo Code
            if (orderSummary.promoCodeObj && orderSummary.promoCodeObj.shipping && (orderSummary.promoCodeObj.shipping.total <= element.shippingTotal)) {
              orderSummary.promocodeSavings += orderSummary.shippingTotal - (+orderSummary.promoCodeObj.shipping.total);
              orderSummary.shippingTotal = (+orderSummary.promoCodeObj.shipping.total).toFixed(2);
            } else {
              orderSummary.shippingTotal = +(element.shippingTotal ? element.shippingTotal.toFixed(2) : 0);
            }
            orderSummary.estimatedTotal = (+subTotal +
              +element.hazmatHandlingFee + +orderSummary.shippingTotal).toFixed(2);
          }
          orderSummary.hazmatHandlingFee = +element.hazmatHandlingFee.toFixed(2);
          orderSummary.rushOrderFee = +element.rushOrderFee.toFixed(2);
          orderSummary.rushOrderFeePrice = +element.rushOrderFeePrice.toFixed(2);
          orderSummary.shippingMethod = element.shippingMethod;
          orderSummary.earliestDeliveryDate = element.earliestDeliveryDate;
          orderSummary.latestDeliveryDate = element.latestDeliveryDate;
        }
      });
    }
    orderSummary.orderSubtotal = +subTotal.toFixed(2);
    orderSummary.salesTax = (orderSummary && orderSummary.salesTax) ? +orderSummary.salesTax.toFixed(2) : 0;
    return orderSummary;
  }

  getRushOrderFees(url: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/shippingMethod${url}`);
  }

  getRushOrderFeesPromise(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return resolve(this.http.get(`${Constants.baseURL}/shippingMethod${url}`).toPromise());
    });
  }

  applyPromoCode(url: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/promocode/${url}`);
  }

  removePromoCode(url: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/promocode/${url}`);
  }

  placeOrder(body: any, isLoggedIn: boolean): Observable<any> {
    if (isLoggedIn) {
      return this.http.post(`${Constants.baseURL}/accounts/account/customer/orders`, body);
    } else {
      return this.http.post(`${Constants.baseURL}/orders`, body);
    }
  }

  getSalesTax(url: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/${url}`);
  }

  getSalesTaxPromise(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.http.get(`${Constants.baseURL}/${url}`));
    });
  }

  paypalPatchOrder(body: any, orderId?: string): Observable<any> {
    return this.http.post(`${Constants.paypal.payPalAPIUrlTest}/v1/oauth2/token`, 'grant_type=client_credentials', {
      headers: new HttpHeaders({
        Accept: `application/json`,
        Authorization: `Basic ${this.basicAuth}`
      })
    }).pipe(
      mergeMap((data: any) => {
        return this.http.patch(`${Constants.paypal.payPalAPIUrlTest}/v2/checkout/orders/${orderId}`, body, {
          headers: new HttpHeaders({
            Accept: `application/json`,
            Authorization: `Bearer ${data.access_token}`
          })
        });
      })
    );
  }

  paypalGetOrder(orderId?: string): Observable<any> {
    return this.http.post(`${Constants.paypal.payPalAPIUrlTest}/v1/oauth2/token`, 'grant_type=client_credentials', {
      headers: new HttpHeaders({
        Accept: `application/json`,
        Authorization: `Basic ${this.basicAuth}`
      })
    }).pipe(
      mergeMap((data: any) => {
        return this.http.get(`${Constants.paypal.payPalAPIUrlTest}/v2/checkout/orders/${orderId}`, {
          headers: new HttpHeaders({
            Accept: `application/json`,
            Authorization: `Bearer ${data.access_token}`
          })
        });
      })
    );
  }

  paypalCheckout(url: string, body: any): Observable<any> {
    return this.http.post(`${Constants.paypal.payPalAPIUrlTest}/v1/oauth2/token`, 'grant_type=client_credentials', {
      headers: new HttpHeaders({
        Accept: `application/json`,
        Authorization: `Basic ${this.basicAuth}`
      })
    }).pipe(
      mergeMap((data: any) => {
        return this.http.post(url, body, {
          headers: new HttpHeaders({
            Accept: `application/json`,
            Authorization: `Bearer ${data.access_token}`
          })
        });
      })
    );
  }

  applePaypaymentSessionValidate(url: string, body: any): Observable<any> {
    return this.http.post(`${Constants.baseURL}/applepayValidate`, body);
  }

  getOrderDetails(id: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/orders/${id}`);
  }

  createOrderObj(orderObj: any, customerOrderData: any): void {
    // Build SKUS Object for Order POST endpoint
    this.loaderService.show();
    orderObj.skus = [];
    customerOrderData.skus.forEach(element => {
      const skuData: any = {
        id: element.id,
        sellPrice: element.sellPrice,
        quantity: element.quantity,
        uom: element.uom,
        uomQty: element.uomQty
      };
      orderObj.skus.push(skuData);
    });

    // Save order into JD API
    const orderData: any = {};
    Object.assign(orderData, orderObj);
    try {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(Constants.googleAnalytics.siteId, { action: 'CHECKOUT' })
          .then((token) => {
            orderData.validationToken = token;
            orderData.validationAction = 'CHECKOUT';
            this.placeOrder(orderData, this.isLoggedIn).subscribe(response => {
              this.loaderService.show();
              const cartId = this.storageService.getItem('cartId');
              // Update Google Analytics with Checkout Option Data
              const event = {
                url: '/checkout/order-confirmed'
              };
              this.googleAnalyticsService.emitCheckoutOptions(event, response);
              // Update Google Analytics with Purchased Event Tracking Data
              this.googleAnalyticsService.emitPurchaseEventTracking(Constants.googleAnalytics.eventTags.purchase, response);
              this.bingAnalyticsService.orderTracker(response);
              this.deleteSKUSformCart(this.isLoggedIn, customerOrderData, cartId, response);
            }, (error) => {
              this.loaderService.hide();
              if (customerOrderData.paypalPayments) {
                this.abortPaypalTransaction(customerOrderData.paypalPayments).subscribe(res => {
                  if (res.status === 'COMPLETED') {
                    this.storageService.sessionRemoveItem('orderObj');
                    this.pushOrderObjData(null);
                    this.notificationService.showError(error + ', Payment refunded to source.', 'Error', true);
                    this.router.navigate(['/checkout/info-shipping'], { queryParams: { route: 'info-shipping', isPaypalCheckout: false } });
                  }
                }, err => {
                  this.notificationService.showError(err, 'Error', true);
                });
              } else {
                let htmlTag = `<strong class="checkout-page-error">${error.length > 350 ? error.substring(0, 350).trim() + '...' : error}</strong>`;
                if(error.includes('300')) {
                  htmlTag += `<a href="cart" class="place-order-toater-button bg-transparent font-semibold py-2 px-4 border-2 border-white rounded-full no-underline button">Review Cart</a>`;
                } else if(error.includes('200')) {
                  htmlTag += `<a class="place-order-toater-button update-shipping-information bg-transparent font-semibold py-2 px-4 border-2 border-white button">Update Shipping Information</a>`;
                } else if(error.includes('100')) {
                  htmlTag += `<a class="place-order-toater-button update-billing-information bg-transparent font-semibold py-2 px-4 border-2 border-white button">Update Billing Information</a>`;
                }
                this.notificationService.showError(htmlTag, 'Error', true);
              }
            });
          }), err => {
            this.loaderService.hide();
          };
      });
    } catch (err) {
      this.loaderService.hide();
    }
  }

  abortPaypalTransaction(paypalPaymentsInfo: any): Observable<any> {
    return this.http.post(`${Constants.paypal.payPalAPIUrlTest}/v1/oauth2/token`, 'grant_type=client_credentials', {
      headers: new HttpHeaders({
        Accept: `application/json`,
        Authorization: `Basic ${this.basicAuth}`
      })
    }).pipe(mergeMap((data: any) => {
      const refundUrl = paypalPaymentsInfo.captures[0].links[1].href;
      return this.http.post(refundUrl, {}, {
        headers: new HttpHeaders({
          Accept: `application/json`,
          Authorization: `Bearer ${data.access_token}`
        })
      });
    })
    );
  }

  deleteSKUSformCart(isLoggedIn: boolean, customerOrderData: any, cartId?: any, response?: any): void {
    this.clearCart(isLoggedIn, customerOrderData);
    this.storageService.sessionSetItem('orderedData', response);
    this.storageService.sessionRemoveItem('orderSummary');
    this.storageService.sessionRemoveItem('customerOrder');
    this.storageService.sessionRemoveItem('orderObj');
    this.pushOrderSummary(null);
    this.pushCustomerOrderDetails(null);
    this.pushOrderObjData(null);
    this.storageService.removeItem('poBox');
    this.loaderService.hide();
    this.notificationService.showSuccess('Order placed successfully');
    this.router.navigate(['/checkout/order-confirmed/', response.id]);
  }

 


  clearCart(isLoggedIn: boolean, cartData: any): void {
    const cartId = this.storageService.getItem('cartId');
    if (isLoggedIn) {
      cartData.skus.forEach(element => {
        this.cartService.deleteShoppingCartSkusById(cartId, element.id).subscribe(res => {
        }, err => {
          this.notificationService.showError(err, 'Error', true);
        });
      });
    } else {
      cartData.skus.forEach(element => {
        this.cartService.deleteShoppingCartSkusByIdWithoutAccount(cartId, element.id).subscribe(res => {
        }, err => {
          this.notificationService.showError(err, 'Error', true);
        });
      });
    }
    this.cartService.updateCartValue(0.00);
    this.storageService.setItem('subTotal', '0');
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

}

import { LocationStrategy, isPlatformBrowser } from '@angular/common';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Router, RoutesRecognized, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { Subscription, Subject, of } from 'rxjs';
import { filter, pairwise, debounceTime, distinctUntilChanged, map, switchMap, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment.prod';

import { isObject, isNullOrUndefined } from 'util';
import { CartService } from '../cart/cart.service';
import { LoginService } from '../login/login.service';
import { ProductService } from '../product/product.service';
import { Constants } from '../shared/constants';
import { BingAnalyticsService } from '../shared/services/bing-analytics.service';
import { GeneralOperationsService } from '../shared/services/general-operations.service';
import { GoogleAnalyticsService } from '../shared/services/google-analytics.service';
import { LoaderService } from '../shared/services/loader.service';
import { NotificationService } from '../shared/services/notification.service';
import { SearchSpringScriptsService } from '../shared/services/search-spring-scripts.service';
import { SeoLdSchemaService } from '../shared/services/seo-ld-schema.service';
import { StorageService } from '../shared/services/storage.service';
import { TitleMetadataService } from '../shared/services/title-metadata.service';
import { VersionCheckService } from '../shared/services/version-check.service';
import { v4 as uuidv4 } from 'uuid';
import { BloomreachService } from '../shared/services/bloomreach.service';


@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, AfterContentChecked {



  annouceCartUpdated : boolean = false
  zendeskGuide: string = Constants.zendeskGuide;
  jobsLink: string = Constants.jobsLink;
  cartValue: any = 0;
  menuCategoryList: any;
  showHeaderSection: boolean = false;
  isLoggedIn: boolean = false;
  subMenuCategoryList: any;
  intelliSuggestResponse: any;
  pagination: any;
  userName: string = 'Login';
  FirstName: string;
  showMenu: boolean = false;
  showAccountMenu: boolean = false;
  showMobileMenu: boolean;
  showSubMenuMobileView: boolean;
  showGlobalSearch: boolean;
  twitterLink = Constants.twitterLink;
  youtubeLink = Constants.youtubeLink;
  facebookLink = Constants.facebookLink;
  instagramLink = Constants.instagramLink;
  helpCenterURL = Constants.helpCenter;
  jdProURL = Constants.jdProURL;
  productSupportURL = Constants.productSupportURL;
  projectGuidesURL = Constants.projectGuidesURL;
  @ViewChild('loginComponent') loginComponentTemplate;
  showSubMenu: boolean;
  termCount: number = 5;
  productCount: number = 6;
  searchForm = this.fb.group({
    query: ['', Validators.required],
  });
  isSearchTextEntered: boolean = true;

  active: any;
  showCartLoader: boolean = false;
  toasterNotification: any;

  private subscription: Subscription;
  private userSub: Subscription;
  private routeSub: Subscription;
  private seoLdSchemaSub: Subscription;

  enableWhiteListing: boolean = true;
  showCart = false;
  shoppingCartByIdData: any = {};
  subtotal = 0;
  cartEmptyMessage = false;
  breadcrumbSchema: any = {};
  organizationSchema: any = {
    "@context": "http://schema.org/",
    "@type": "Organization",
    "name": "Jamestown Distributors",
    "url": "https://www.jamestowndistributors.com/home",
    "hasProductReturnPolicy": 
    {
      "productReturnLink": "https://support.jamestowndistributors.com/hc/en-us/sections/360008415273-Order-Cancellation-Returns-FAQs",
      "productReturnCategory": "Finite",
      "productReturnDays": "30",
      "refundType": "FullRefund",
      "inStoreReturnsOffered": "False",
      "productReturnExceptions": "Hazardous materials (such as paints, epoxies, and varnishes) clearance items, electronics, boat covers and cut lengths of material."
    }
  };
  reviewSchema: any = {};
  navigationTime: any;
  rightsReservedYear: any;
  privacyPolicy: string = Constants.privacyPolicy;
  navigateUserAccountsUrl: string = '';
  isPopState = false;
  routeScrollPositions: { [url: string]: number } = {};
  deferredRestore = false;
  eventData: any;
  isConnected: boolean = true;
  searchSpringPageLoaded: string;
  showMobileNavigation: boolean = false
  tabIndex: number = 5
  searchResultTabIndex: number = 0

  private searchText$ = new Subject<string>();

  private keysPressed: { [key: string]: boolean } = {};

  @HostListener('document:keydown', ['$event'])
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: any): void {
    this.keysPressed[event.key] = event.type === 'keydown';
    if (this.keysPressed['/'] && this.keysPressed['s']) {
      console.log('Both / and S keys pressed simultaneously');
      this.keysPressed['/'] = false;
      this.keysPressed['s'] = false;
      this.showMenu = !this.showMenu;
      this.showSubMenu = !this.showSubMenu;
      let shopMenu = document.getElementById('header-shop-menu');
      if (shopMenu) {
        shopMenu.focus()
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  onTabKeyPress(event: any) {
    if (event.shiftKey && event.keyCode == 9) {
      if(event.target['id'] == 'clear-cart-cancel-button') {
        setTimeout(() => {
          let el = document.getElementById('clear-cart-confirm-button');
          if(el) {
            el.focus()
          }
        })
      } else if (event.target['id'] == 'clear-cart-confirm-popup-heading') {
        setTimeout(() => {
          let el = document.getElementById('clear-cart-cancel-button');
          if(el) {
            el.focus()
          }
        })
      }
    } else if (event.target['id'] == 'clear-cart-cancel-button' && event.keyCode == 9) {
      setTimeout(() => {
        let el = document.getElementById('clear-cart-confirm-popup-heading');
        if(el) {
          el.focus()
        }
      })
    }
  }
  constructor(
    @Inject(DOCUMENT) private dom,
    @Inject(PLATFORM_ID) private platform: any,
    private changeDetectionRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private titleMetadataService: TitleMetadataService,
    private notificationService: NotificationService,
    private productService: ProductService,
    private storageService: StorageService,
    private cartService: CartService,
    private loaderService: LoaderService,
    private seoLdSchemaService: SeoLdSchemaService,
    private searchSpringScriptsService: SearchSpringScriptsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private elementRef: ElementRef,
    private versionCheckService: VersionCheckService,
    private generalOperationsService: GeneralOperationsService,
    private bingAnalyticsService: BingAnalyticsService,
    private locationStrategy: LocationStrategy,
    private connectionService: ConnectionService,
    private bloomreachService: BloomreachService
  ) {

    // router.events.subscribe((val) => {
    //   if(val['url'] == '/home') {
    //     const homeLogo: any = document.getElementById('header__logo_wrapper');
    //     if(homeLogo) {
    //       homeLogo.focus()
    //     }
    //   }
    // });

    router.events.subscribe((val) => {
      // if(val['url'] == '/home') {
        const homeLogo: any = document.getElementById('first-tab-index');
        if(homeLogo) {
          homeLogo.focus()
        }
      // }
    });

    this.checkWindowSize()
    if (isPlatformBrowser(this.platform)) {
      window.addEventListener('resize', () => {
        this.checkWindowSize()
      });
      window.addEventListener('load', () => {
        var options = { 
          icon: { 
            position: { 
              bottom: { 
                size: 20, 
                units: 'px' 
              }, 
              left: { 
                size: 20, 
                units: 'px' 
              }, 
              type: 'fixed' 
            } 
          } 
        }
      }, false)
    }
    this.titleMetadataService.updateTitle();
    this.titleMetadataService.updateDescription();
    this.titleMetadataService.updateMetaInfo('');

    this.subscription = this.cartService.onCartChange.subscribe(value => {
      this.cartValue = value;
      this.updateSearchSpringCart();
    });
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/home') {
          if (this.isLoggedIn) {
            this.getShoppingCart();
          } else {
            this.getLocalShoppingCart();
          }
        }
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
    // Shows and hides the loader icon during RouterEvent changes
    this.routeSub = this.router.events.subscribe((event) => {
      this.searchSpringPageLoaded = uuidv4();
      this.searchSpringScriptsService.searchSpringPageLoaded.next(this.searchSpringPageLoaded);
      this.navigationInterceptor(event);

      this.breadcrumbSchema = null;
      if (this.router.url.indexOf('checkout') >= 1) {
        this.showHeaderSection = true;
      } else {
        this.showHeaderSection = false;
      }
      this.showCart = false;
    });

    this.routeSub = this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise()).subscribe((events: RoutesRecognized[]) => {
      if (events instanceof Array && events[1].urlAfterRedirects.indexOf('product-detail') > -1) {
        this.storageService.setItem('prevURL', events[0].urlAfterRedirects);
      } else {
        this.storageService.removeItem('prevURL');
      }
    });

    // Notify user to login and open modal
    this.notificationService.notify.subscribe((result) => {
      if (result) {
        this.toggleModal();
      }
    });

    this.pagination = {
      pageNumber: 0,
      pageSize: 100
    };

    // this.searchText$.pipe(
    //   debounceTime(500),
    //   distinctUntilChanged(),
    //   map(data => {
    //     this.eventData = data;
    //     if (this.searchForm.controls.query.value) {
    //       return encodeURIComponent(this.searchForm.controls.query.value.trim());
    //     }
    //   }),
    //   switchMap(query =>
    //     this.productService.getIntelliSuggestProducts(
    //       query,
    //       this.termCount,
    //       this.productCount)
    //   )
    // ).subscribe(data => {
    //   this.processSearchResult(this.eventData, data);
    // });
    this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(data => {
        this.eventData = data; // Store data in the outer scope
        if (this.searchForm.controls.query.value) {
          const query = encodeURIComponent(this.searchForm.controls.query.value.trim());
          return this.productService.getIntelliSuggestProducts(
            query,
            this.termCount,
            this.productCount
          ).pipe(
            catchError(error => {
              // Handle the error (you can log it or perform other actions)
              // console.error('Error fetching products:', error);
              // Return an empty array to continue the chain
              return of([]);
            })
          );
        } else {
          // Return an empty observable with an empty array if query is empty
          return of([]);
        }
      })
    ).subscribe(data => {
      this.processSearchResult(this.eventData, data);
    });
    this.getToasterNotifications();

  }

  isFocused: boolean = false;

  onFocus() {
    this.isFocused = true;
   setTimeout(() => {
    let skipBtn:any = document.getElementsByClassName('skip-to-main-content-link')[0]
    if(skipBtn) {
      skipBtn.focus()
    }
   }, 100);
  }

  focusOnMainContent() {
    let mainContent = document.getElementById('main-content');
    let focusableElements = mainContent.querySelectorAll('a[href], button, input, select, textarea, [tabindex]');
    let firstVisibleFocusableElement:any = Array.from(focusableElements).find((element:any) => {
        let computedStyle = isPlatformBrowser(this.platform) ? window.getComputedStyle(element) : null;
        return element.offsetParent !== null && element.disabled !== true && computedStyle.display !== 'none' && computedStyle.visibility !== 'hidden';
    });

    if (firstVisibleFocusableElement) {
        firstVisibleFocusableElement.focus();
    }
}


  onBlur() {
    this.isFocused = false;
  }
  
  checkWindowSize() {
    if (isPlatformBrowser(this.platform)) {
      if (window.innerWidth < 960) {
        this.showMobileNavigation = true
      } else {
        this.showMobileNavigation = false
      }
    }
  }

  onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');
    this.searchForm.controls.query.patchValue(pastedText);
    this.search(pastedText)
    event.preventDefault();
  }

  stopScrolling(event:any) {
    event.preventDefault(); // This will prevent the default behavior (scrolling)
    // Add any other actions you want to perform when Enter is pressed
  }

  search(data: any) {
    const query = this.searchForm.controls.query.value.trim();
    if (!query) {
      this.showGlobalSearch = false;
      return;
    }

    if (query.length > 256) {
      this.showGlobalSearch = false;
      this.notificationService.showWarning('Search query exceeds the maximum length of characters');
      return;
    }

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
    const isTextEntered = this.isEmptyOrSpaces(query);

    if ((data.code === 'KeyV' || data.code === 'Enter' || data.code === 'NumpadEnter') && !isTextEntered) {
      this.searchText$.next(data);
    } else if (
      (data.keyCode > 47 && data.keyCode < 91) ||
      (data.keyCode > 95 && data.keyCode < 106) ||
      data.keyCode === 8 ||
      data.keyCode === 46 ||
      (data.keyCode === 17 || data.keyCode === 91 || data.keyCode === 55) ||
      (data.keyCode === 32 && !isTextEntered)
    ) {
      this.isSearchTextEntered = false;
      this.searchText$.next(data);
    }

    if (!isTextEntered && query.length > 0) {
      this.isSearchTextEntered = false;
      this.searchText$.next(data);
    } else if (!isTextEntered && query.length > 0 && data.code !== 'Enter' && data.code !== 'NumpadEnter') {
      this.isSearchTextEntered = true;
    }

    if (isMobile) {
      this.isSearchTextEntered = false;
      this.showGlobalSearch = true;
      this.searchText$.next(data);
    }
  }
  applyDropDownTabIndex() {
    this.tabIndex = this.searchResultTabIndex == 0 ? 7 : this.searchResultTabIndex + 1
    this.menuCategoryList.forEach((menu, i) => {
      menu.url = (`/product/${menu.name.replace(/ /g, '-')}`).toLowerCase();
      menu.tabIndex = this.tabIndex
      this.tabIndex++
      if (menu.subcategories) {
        menu.subcategories.forEach((subMenu, j) => {
          subMenu.tabIndex = this.tabIndex
          this.tabIndex++
        });
      }
    });
  }
  processSearchResult(keycode = null, response) {
    this.searchResultTabIndex = 5
    this.changeDetectionRef.detectChanges()
    this.intelliSuggestResponse = {};
    if (response && response.query) {
      this.showGlobalSearch = true;
      this.intelliSuggestResponse = {};
      this.intelliSuggestResponse.alternatives = response.alternatives;
      this.intelliSuggestResponse.query = response.query;
      if (response['corrected-query']) {
        this.intelliSuggestResponse.isCorrectQuery = true;
        this.intelliSuggestResponse.correctedQuery = response['corrected-query'];
      } else {
        this.intelliSuggestResponse.isCorrectQuery = false;
        this.intelliSuggestResponse.correctedQuery = this.intelliSuggestResponse.query;
      }
      if (response.suggested) {
        this.intelliSuggestResponse.suggested = response.suggested;
        this.intelliSuggestResponse.terms = response.suggested.completed;
        if( this.intelliSuggestResponse.terms) {
          this.intelliSuggestResponse.terms.forEach((element: any) => {
            element.searchResultTabIndex = this.searchResultTabIndex
            this.searchResultTabIndex++
            this.changeDetectionRef.detectChanges()
          });
        }
        this.applyDropDownTabIndex()

        if (this.intelliSuggestResponse.alternatives.length) {
          this.intelliSuggestResponse.alternatives.forEach((element: any) => {
            element.searchResultTabIndex = this.searchResultTabIndex
            this.searchResultTabIndex++
            this.changeDetectionRef.detectChanges()
          });
          this.applyDropDownTabIndex()
        }
      } else {
        this.intelliSuggestResponse.suggested = {
          text: response['corrected-query'] ? response['corrected-query'] : response.query
        };
      }
      if (keycode !== null && (keycode.code === 'Enter' || keycode.code === 'NumpadEnter') && (this.searchForm.controls.query.value != null && this.searchForm.controls.query.value.length > 0)) {
        this.navigateWithQuery(this.intelliSuggestResponse.correctedQuery, true);
      } else {
        // Call SearchSpring API to get the Products
        const url = `&q=${this.intelliSuggestResponse.suggested.text}&page=${0}&resultsPerPage=${this.productCount}`;
        this.productService.getAutoCompleteSearchSpringResponse(url).subscribe(res => {
          this.intelliSuggestResponse.products = res.results;
          this.intelliSuggestResponse.products.forEach((element: any) => {
            element.searchResultTabIndex = this.searchResultTabIndex
            this.searchResultTabIndex++
            this.changeDetectionRef.detectChanges()
          });
          this.applyDropDownTabIndex()
          if (this.intelliSuggestResponse.products && this.intelliSuggestResponse.products.length < 1) {
            this.intelliSuggestResponse.products = response.products;
            this.intelliSuggestResponse.products.forEach((element: any) => {
              element.searchResultTabIndex = this.searchResultTabIndex
              this.searchResultTabIndex++
              this.changeDetectionRef.detectChanges()
            });
            this.applyDropDownTabIndex()
          }
        }, error => {
          this.notificationService.showError(error);
        });
      }
    } else if (response && response.query) {
      this.intelliSuggestResponse.correctedQuery = this.intelliSuggestResponse.query = response.query;
      this.showGlobalSearch = false;
      if (keycode !== null && (keycode.code === 'Enter' || keycode.code === 'NumpadEnter') && (!this.isEmptyOrSpaces(this.searchForm.controls.query.value) && this.searchForm.controls.query.value.length > 0)) {
        this.navigateWithQuery(this.intelliSuggestResponse.correctedQuery, true);
      }
    }
  }

  isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      // this.versionCheckService.initVersionCheck(environment.versionCheckURL);
      // prevent nguniversal problems
      this.addScrollTopListeners();
      this.connectionService.monitor().subscribe(isConnected => {
        this.isConnected = isConnected;
        if (!this.isConnected) {
          this.notificationService.showWarning(`Your request failed. Please make sure you're connected to the internet and try again.`);
        }
      });
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
      this.isSearchTextEntered = false;
    } else {
      this.isSearchTextEntered = true;
    }
    // Remove this when live
    if (environment.domainURL === 'https://www.jdprodwebapp.com') {
      this.titleMetadataService.addMetaTags('robots', 'noindex');
    }
    this.rightsReservedYear = new Date().getFullYear();
    this.seoLdSchemaSub = this.seoLdSchemaService.ldSchema.subscribe((result) => {
      if (isObject(result)) {
        this.constructLDSchema(result);
      } else {
        this.breadcrumbSchema = null;
        this.reviewSchema = null;
      }
    });

    this.userSub = this.loginService.showLoginModal.subscribe(val => {
      if (val.isShowLoginModal) {
        this.active = val.isShowLoginModal;
        this.navigateUserAccountsUrl = val.url;
      }
    });

    this.userSub = this.loginService.user.subscribe(user => {
      this.getCategories('', '');
      if (!!user) {
        if (this.loginService.getActiveSession()) {
          this.userName = `Hi, ${user.given_name}`;
          this.isLoggedIn = true;
          this.getShoppingCart();
        } else {
          this.cartService.onCartChange.next(true);
          this.loginService.renewSessionWithRefreshToken().then(resolve => {
            // on success user logged in autmoatically
          }, reject => {
            // do nothing
          });
        }
      } else {
        this.cartService.onCartChange.next(true);
        this.userName = 'Login';
        this.isLoggedIn = false;
        this.cartService.updateCartValue(0.00);
        this.getLocalShoppingCart();
      }

      this.bloomreachService.loadBloomreachIntegration();

      if(user) {
        const customerPropertiesData = {
          email: user.email,
          first_name: user.given_name,
          last_name: user.family_name,
        }
        this.bloomreachService.identifyAndUpdateCustomerProperties(customerPropertiesData);
      }
    });
    // Update Page Load Timings on Google Analytics
    if (window.performance) {
      const loadTime = Math.round(performance.now());
      this.googleAnalyticsService.emitGTagEvent('timing_complete', Constants.googleAnalytics.eventTags.applicationLoad, loadTime.toString());
    }
    this.cartService.onCartChange.subscribe(res => {
      if (this.isLoggedIn) {
        this.getShoppingCart(false);
      } else {
        this.getLocalShoppingCart(false);
      }
    })
   
  }

  ngAfterViewInit() {

    var that = this
    var shopMenu = that.dom.getElementById("header-shop-menu");
    if (shopMenu) {
      shopMenu.addEventListener("keypress", function (event: any) {
        if (event.key === "Enter" || event.code == "Space") {
          event.preventDefault();
          that.showMenuEvent();
        }
      });

      shopMenu.addEventListener("keydown", function (event: any) {
        if (event.code == "ArrowDown") {
          event.preventDefault();
          that.showMenuEvent();

          setTimeout(() => {
            that.dom.getElementById("shop-menu-category-0").focus();
          }, 150)
        }
      });
    }

    var shopMenuContainer = that.dom.getElementById("shop-menu-container");
    if (shopMenuContainer) {
      shopMenuContainer.addEventListener("keydown", function (event: any) {
        if(event.code == 'ArrowUp' || event.code == 'ArrowDown') {
          event.preventDefault();
        }
      });
    }
  }

  showMenuEvent() {
    var that = this
    that.showMenu = !that.showMenu;
    that.showSubMenu = !that.showSubMenu;
    setTimeout(() => {
      that.dom.getElementById("shop-menu-category-0").focus();
    }, 10)
  }

  arrowDownMenu(event: any, key:any) {
    event.preventDefault();
    var currInput = document.activeElement;
    if (currInput.hasAttribute('tabindex')) {
        var tabindex = parseInt(currInput.getAttribute('tabindex'));
        var nextIndex = (key === 'ArrowDown') ? tabindex + 1 : (key === 'ArrowUp') ? tabindex - 1 : null;
        if (!isNaN(nextIndex)) {
            var next = document.querySelector('[tabindex="' + nextIndex + '"]');
            if (next) {
              (next as HTMLElement).focus();
            } else {
              this.findShopMenu(key);
            }
        }
    }
  }

  findShopMenu(key: any) {
    var currInput = document.activeElement;
    if (currInput.tagName.toLowerCase() == "a") {
      var inputs = document.getElementsByTagName("a");
      var currInput = document.activeElement;
      for (var i = 0; i < inputs.length; i++) {
          if (inputs[i] == currInput) {
            var index = 0;
            if(key == 'ArrowDown') {
              index = i + 1;
            } else if(key === 'ArrowUp') {
              index = i - 1;
            }
              var next = inputs[index];
              if (next && next.focus) {
                  next.focus();
              }
              break;
          }
      }
    }
  }

  shopClickMenu() {
    setTimeout(() => {
      if(this.showMenu == false && this.showSubMenu == false) {
      this.showMenu = !this.showMenu;
      this.showSubMenu = !this.showSubMenu;
      }
    }, 10)
  }

  init:boolean = false
  ngAfterViewChecked() {
    this.changeDetectionRef.detectChanges();
  }

  getToasterNotifications() {
    this.generalOperationsService.getToasterNotifications('?page=0&size=10').subscribe(
      res => {
        this.toasterNotification = res;
      }, err => { }
    );
  }

  showHideGlobalSearch(isShow): void {
    this.showGlobalSearch = isShow;
    window.scrollTo(0, 0);
  }

  // Shows and hides the loader icon during RouterEvent changes
  navigationInterceptor(event: any): void {
    switch (true) {
      case event instanceof NavigationStart: {
        const navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras && navigation.extras.state) {
          this.storageService.setItem('navigationExtra', navigation.extras.state.data);
        } else {
          this.storageService.removeItem('navigationExtra');
        }
        this.navigationTime = new Date().getTime();
        if (event.url.indexOf('/checkout/info-shipping') >= 0 || event.url.indexOf('/product/') >= 0) {
        }
        this.getToasterNotifications();
        break;
      }
      case (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError): {
        if (event.url.indexOf('/checkout/info-shipping') >= 0 || event.url.indexOf('/product/') >= 0) {
        }
        // Update Page Navigation Timings on Google Analytics
        this.navigationTime = new Date().getTime() - this.navigationTime;
        this.googleAnalyticsService.emitGTagEvent('timing_complete', Constants.googleAnalytics.eventTags.pageNavigation, this.navigationTime);
        // Update Page browse activity on Google Analytics
        this.googleAnalyticsService.emitPageBrowseActivity('page', event.urlAfterRedirects);
        this.bingAnalyticsService.pagePathTracker(event.urlAfterRedirects);
        break;
      }
      default: {
        break;
      }
    }
  }

  getCategories(category: string, subCategory: string): void {
    this.productService.getCategories(this.pagination, category, subCategory).subscribe(response => {
      this.tabIndex = 7
      if (response && response.content) {
        this.menuCategoryList = response.content;
        if (this.menuCategoryList && this.menuCategoryList.length) {
          this.menuCategoryList.forEach((menu, i) => {
            menu.url = (`/product/${menu.name.replace(/ /g, '-')}`).toLowerCase();
            menu.tabIndex = this.tabIndex
            this.tabIndex++
            if (menu.subcategories) {
              menu.subcategories.forEach((subMenu, j) => {
                subMenu.url = (`/product/${menu.name.replace(/ /g, '-')}/${subMenu.name.replace(/ /g, '-')}`).toLowerCase();
                subMenu.tabIndex = this.tabIndex
                this.tabIndex++
              });
            }
          });
          this.subMenuCategoryList = this.menuCategoryList[0];
        }
        this.storageService.setItem('categoryList', this.menuCategoryList);
      }
    }, error => {
      this.notificationService.showError(error);
    });
  }

  navigateToProductLanding(category: any, subCategory: any): void {
    this.showMenu = false;
    this.showMobileMenu = false;
    this.showSubMenuMobileView = false;
    this.searchForm.reset();
    this.isSearchTextEntered = true;
  }

  navigateWithQuery(term: string, isEnterPressed = false): any {
    if (isEnterPressed) {
      if (isNullOrUndefined(this.intelliSuggestResponse) || isNullOrUndefined(this.intelliSuggestResponse.query)) {
        return;
      }
      this.intelliSuggestResponse.query = this.intelliSuggestResponse.correctedQuery = term;
      if (this.intelliSuggestResponse && this.intelliSuggestResponse.suggested) {
        this.intelliSuggestResponse.correctedQuery = this.intelliSuggestResponse.suggested.text;
      }
    }
    this.showGlobalSearch = false;
    term = this.intelliSuggestResponse.correctedQuery.replace(/<[^>]*>/g, '');
    let query = `&q=${this.intelliSuggestResponse.correctedQuery.replace(/ /g, '-')}&category=true`;
    let queryParams: any = {
      queryBy: query
    }
    if (this.intelliSuggestResponse.correctedQuery) {
      this.intelliSuggestResponse.correctedQuery = this.intelliSuggestResponse.correctedQuery.toLowerCase();
    }
    this.intelliSuggestResponse.query = this.intelliSuggestResponse.query.toLowerCase();
    if (this.intelliSuggestResponse.correctedQuery !== this.intelliSuggestResponse.query) {
      queryParams = {
        queryBy: query,
        origQuery: this.intelliSuggestResponse.query,
        correctedQuery: this.intelliSuggestResponse.correctedQuery
      }
    }
    this.storageService.setItem('currentURL', this.router.url);
    this.router.navigate([`/product`],
      {
        queryParams: queryParams,
      }
    );
    this.searchForm.reset();
    this.isSearchTextEntered = true;
  }

  totalboatNavigation(): void {
    this.showMobileMenu = false;
  }

  loginPopup(status: any): void {
    this.active = !this.active;
    if(status == 'open') {
      let el = this.dom.getElementById('txtEmailId2');
      if(el) {
        let that = this
        setTimeout(() => {
          el.focus();
          var headerContentDiv = that.dom.getElementById("header-content");
          var mainContentDiv = that.dom.getElementById("main-content");
          var footerContentDiv = that.dom.getElementById("footer-content");
          headerContentDiv.addEventListener("focusin", function () {
            let element:any = that.dom.getElementById("jsCloseModal")
            element.focus();
          });
          mainContentDiv.addEventListener("focusin", function () {
            let element:any = that.dom.getElementById("jsCloseModal")
            element.focus();
          });
          footerContentDiv.addEventListener("focusin", function () {
            let element:any = that.dom.getElementById("jsCloseModal")
            element.focus();
          });
        }, 10)
      }
    } else {
        let el = this.dom.getElementById('login-header-menu');
        if(el) {
          setTimeout(() => {
            el.focus();
          }, 200)
        }
    }
  }

  logout(): void {
    this.loginService.logout(true);
    this.showAccountMenu = false;
    this.loginService.updateLoggedOutValue(false);
  }

  toggleModal(): void {
    this.active = !this.active;
    if (this.navigateUserAccountsUrl !== '') {
      this.storageService.setItem('returnURL', this.navigateUserAccountsUrl);
      this.navigateUserAccountsUrl = '';
    }
  }

  hideGlobalSearch(): void {
    this.searchForm.reset();
    this.intelliSuggestResponse = {};
    this.showGlobalSearch = false;
    this.isSearchTextEntered = true;
    this.searchResultTabIndex = 0
    this.changeDetectionRef.detectChanges()
    this.applyDropDownTabIndex()
  }

  getShoppingCartByIdData(isRemove: boolean = false) {
    if (!isRemove) {
      this.showCart = !this.showCart;
    }
    this.showCartLoader = true;
    if (this.isLoggedIn) {
      this.getShoppingCart(isRemove);
    } else {
      this.getLocalShoppingCart(isRemove);
    }
  }

  getShoppingCart(isRemove: boolean = false): void {
    if (this.storageService.getItem('cartId') && this.storageService.getItem('cartId') !== 'undefined') {
      this.cartService.getShoppingCartByIdWithoutInterceptor(this.storageService.getItem('cartId')).subscribe(data => {
        this.shoppingCartByIdData = data;
        this.calculateSubTotal(isRemove);
        this.showCartLoader = false;
      }, (error) => {
        this.notificationService.showError(error);
        this.showCartLoader = false;
      });
    } else {
      this.showCartLoader = false;
      this.cartEmptyMessage = true;
    }
  }

  getLocalShoppingCart(isRemove: boolean = false): void {
    const cartId = this.storageService.getItem('cartId');
    if (cartId) {
      this.cartService.getShoppingCartByIdWithoutAccountWithoutInterceptor(cartId).subscribe((response) => {
        if (response) {
          this.showCartLoader = false;
          this.shoppingCartByIdData = response;
          this.calculateSubTotal(isRemove);

        } else {
          this.notificationService.showError('No Cart found');
          this.showCartLoader = false;
        }
      }, (error) => {
        this.notificationService.showError(error);
        this.showCartLoader = false;
      });
    } else {
      this.showCartLoader = false;
      this.cartEmptyMessage = true;
      this.shoppingCartByIdData = null;
      this.calculateSubTotal(isRemove);
    }
  }

  calculateSubTotal(isRemove: boolean): void {
    if (this.shoppingCartByIdData && this.shoppingCartByIdData.skus && this.shoppingCartByIdData.skus.length > 0) {
      this.subtotal = this.shoppingCartByIdData.skus.map(product => product.quantity * product.sellPrice).reduce((a, b) => a + b);
      this.storageService.setItem('subTotal', this.subtotal.toString());
      this.cartService.updateCartValue(this.subtotal);
      this.cartValue = this.subtotal;
      this.cartEmptyMessage = false;
    } else {
      this.subtotal = 0;
      this.cartService.updateCartValue(0.00);
      this.storageService.setItem('subTotal', 0);
      this.cartEmptyMessage = true;
      this.cartValue = this.subtotal;

    }
  }

  updateSearchSpringCart(): void {
    const cartId = this.storageService.getItem('cartId');
    if (this.isLoggedIn) {
      if (cartId && cartId !== 'undefined') {
        this.cartService.getShoppingCartByIdWithoutInterceptor(cartId).subscribe(data => {
          this.shoppingCartByIdData = data;
          this.showCartLoader = false;
          this.searchSpringScriptsService.addCartTracking(this.shoppingCartByIdData.skus);
        }, (error) => {
          this.searchSpringScriptsService.addCartTracking([]);
          this.showCartLoader = false;
        });
      } else {
        this.searchSpringScriptsService.addCartTracking([]);
        this.showCartLoader = false;
      }
    } else {
      if (cartId) {
        this.cartService.getShoppingCartByIdWithoutAccountWithoutInterceptor(cartId).subscribe((response) => {
          if (response) {
            this.shoppingCartByIdData = response;
            this.showCartLoader = false;
            this.searchSpringScriptsService.addCartTracking(this.shoppingCartByIdData.skus);
          } else {
            this.showCartLoader = false;
            this.searchSpringScriptsService.addCartTracking([]);
          }
        }, (error) => {
          this.searchSpringScriptsService.addCartTracking([]);
          this.showCartLoader = false;
        });
      } else {
        this.searchSpringScriptsService.addCartTracking([]);
        this.showCartLoader = false;
      }
    }
  }
 
  removeFromCart(productSKU) {
    const action = {
      action: 'remove',
      button_type: 'cart popup',
      button_copy: 'remove',
      page_type: 'cart popup'
    }
    if(productSKU)
    {
      this.annouceCartUpdated = true
    }

    const data = {
      skus: [productSKU]
    };
    const cartId = this.storageService.getItem('cartId');
    if (this.isLoggedIn) {
      this.cartService.deleteShoppingCartSkusById(cartId, productSKU.id).subscribe((response) => {
        this.bloomreachService.trackCartUpdate(productSKU, action);
        this.googleAnalyticsService.emitCartEventTracking(Constants.googleAnalytics.eventTags.removeFromCart, data);
        this.getShoppingCartByIdData(true);
        this.cartService.updateCartDetailPage(true);
        this.cartService.onCartChange.next(true);
      }, (error) => {
        this.notificationService.showError(error);
      });
    } else {
      if (cartId) {
        // Delete SKU from shopping cart without account
        this.cartService.deleteShoppingCartSkusByIdWithoutAccount(cartId, productSKU.id).subscribe((response) => {
          if (response) {
            this.bloomreachService.trackCartUpdate(productSKU, action);
            this.notificationService.showSuccess('Shopping Cart updated');
            this.googleAnalyticsService.emitCartEventTracking(Constants.googleAnalytics.eventTags.removeFromCart, data);
            this.getShoppingCartByIdData(true);
            this.cartService.updateCartDetailPage(true);
            this.cartService.onCartChange.next(true);
          } else {
            this.notificationService.showError('No responseid');
          }
        }, (error) => {
          this.notificationService.showError(error);
        });
      }
    }
  }

  redirectCartPage() {
    this.showCart = false;
    if (this.router.url === '/cart') {
      location.reload();
    }
  }

  constructLDSchema(ldSchemaJson: any): void {
    this.breadcrumbSchema = {};
    let i = 1;
    let encodedURI = encodeURI(`${Constants.domainURL}/home`);
    if (ldSchemaJson.breadcrumbSchemaObj) {
      this.breadcrumbSchema = {
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        itemListElement: [{
          '@type': 'ListItem',
          position: i,
          name: 'Home',
          item: encodedURI
        }]
      };
      if (ldSchemaJson.breadcrumbSchemaObj) {
        ldSchemaJson.breadcrumbSchemaObj.forEach(ldSchemaElement => {
          i += 1;
          ldSchemaElement.url = ldSchemaElement.url.replace(/ /g, '-');
          encodedURI = encodeURI(`${Constants.domainURL}${ldSchemaElement.url}`);
          const obj = {
            '@type': 'ListItem',
            position: i,
            name: ldSchemaElement.name,
            item: encodedURI
          };
          // Add items to LD Schema Links
          this.breadcrumbSchema.itemListElement.push(obj);
        });
      }
    }
    this.reviewSchema = null;
    if (ldSchemaJson.productSchema && ldSchemaJson.productSchema.productDetail) {
      this.constructProductReview(ldSchemaJson);
    }
  }

  constructProductReview(ldSchemaJson: any) {
    this.reviewSchema = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: ldSchemaJson.productSchema.productDetail.name,
      image: ldSchemaJson.productSchema.productDetail.images ? ldSchemaJson.productSchema.productDetail.images[1][0].url : '',
      description: ldSchemaJson.productSchema.productDetail.productDetail,
      brand: ldSchemaJson.productSchema.productDetail.brand ? ldSchemaJson.productSchema.productDetail.brand.name : '',
      sku: `${ldSchemaJson.productSchema.sku.id}`,
      offers: {
        '@type': 'Offer',
        availability: ldSchemaJson.productSchema.availability,
        url: `${Constants.domainURL}${this.router.url}`,
        priceCurrency: `${Constants.currencyUSD}`,
        price: `${ldSchemaJson.productSchema.sku.price ? ldSchemaJson.productSchema.sku.price.sellPrice : ''}`
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '',
        reviewCount: ''
      },
      review: [],
    };
    if (ldSchemaJson.productSchema.ratings && ldSchemaJson.productSchema.ratings.reviews) {
      if (ldSchemaJson.productSchema.ratings.rollup && ldSchemaJson.productSchema.ratings.rollup.average_rating) {
        this.reviewSchema.aggregateRating = {
          ratingValue: `${ldSchemaJson.productSchema.ratings.rollup.average_rating}`,
          reviewCount: `${ldSchemaJson.productSchema.ratings.rollup.review_count}`
        };
      } else {
        delete this.reviewSchema.aggregateRating;
      }
      ldSchemaJson.productSchema.ratings.reviews.forEach(element => {
        const review = {
          '@type': 'Review',
          reviewBody: element.details.comments,
          author: element.details.nickname,
          reviewRating: {
            '@type': 'Rating',
            ratingValue: `${element.metrics.rating}`
          }
        };
        this.reviewSchema.review.push(review);
      })
    } else {
      delete this.reviewSchema.aggregateRating;
      delete this.reviewSchema.review;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.seoLdSchemaSub) {
      this.seoLdSchemaSub.unsubscribe();
    }
  }

  private saveScroll(url) {
    this.routeScrollPositions[url] = window.pageYOffset;
  }

  private restoreScroll(url) {
    const savedScroll = this.routeScrollPositions[url];
    if (savedScroll === undefined) {
      this.deferredRestore = false;
      return false;
    }

    const documentHeight = this.dom.body.clientHeight;
    const windowHeight = window.innerHeight;

    if (savedScroll + windowHeight <= documentHeight) {
      setTimeout(() => {
        window.scrollTo(0, savedScroll);
        this.deferredRestore = false;
      }, 1000);
      return true;
    }

    return false;
  }

  private addScrollTopListeners() {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!this.isPopState) {
          this.saveScroll(this.router.url);
        }
      }

      if (event instanceof NavigationEnd) {
        if (this.isPopState) {
          if (!this.restoreScroll(event.url)) {
            setTimeout(() => {
              if (this.deferredRestore) {
                this.restoreScroll(event.url);
                this.deferredRestore = false;
              }
            }, 100);

            this.deferredRestore = true;
          }
        } else {
          window.scrollTo(0, 0);
        }

        this.isPopState = false;
      }
    });

    this.locationStrategy.onPopState(() => {
      this.isPopState = true;
      this.saveScroll(this.router.url);
    });
  }

  ngAfterContentChecked() {
    if (this.deferredRestore) {
      this.restoreScroll(this.router.url);
    }
  }
}

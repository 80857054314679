import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { Constants } from '../shared/constants';
import { StorageService } from '../shared/services/storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/services/storage.service";
var ProductService = /** @class */ (function () {
    function ProductService(http, storageService) {
        this.http = http;
        this.storageService = storageService;
        this.InterceptorSkipLoader = 'X-Skip-Loader';
    }
    // Get Categories from Jamestown GET Request
    ProductService.prototype.getCategories = function (pagination, category, subCategory) {
        var headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
        var url = Constants.baseURL + "/categories/" + category;
        if (subCategory) {
            url += "/subcategories/" + subCategory;
        }
        url += "?page=" + pagination.pageNumber + "&size=" + pagination.pageSize + " ";
        return this.http.get(url, { headers: headers });
    };
    // searchSpring POST Request
    // Headers added through HTTP Interceptor in the file shared/services/http-error.interceptor.service.ts
    ProductService.prototype.getSearchSpringResponse = function (url) {
        return this.http.get(Constants.searchSpringURL + "/search/search.json?siteId=" + Constants.searchSpringSiteId + url + "&resultsFormat=native");
    };
    ProductService.prototype.getAutoCompleteSearchSpringResponse = function (url) {
        return this.http.get(Constants.autocompleteSearchSpringURL + "/search/autocomplete.json?siteId=" + Constants.searchSpringSiteId + url + "&resultsFormat=native");
    };
    // Get Product Details from Jamestown GET Request
    ProductService.prototype.getProductDetail = function (id) {
        return this.http.get(Constants.baseURL + "/products/" + id);
    };
    // Get Product SKU from Jamestown GET Request
    ProductService.prototype.getProductSKU = function (productId, skuId) {
        var headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
        return this.http.get(Constants.baseURL + "/products/" + productId + "/skus/" + skuId, { headers: headers });
    };
    // Get Recommended Product List searchSpring
    ProductService.prototype.getRecommendedProducts = function (url) {
        var headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
        return this.http.get(Constants.searchSpringURL + "/recommend?siteId=" + Constants.searchSpringSiteId + url, { headers: headers });
    };
    // Get Frequently Purchased Together searchSpring
    ProductService.prototype.getFrequentlyPurchasedProducts = function (url) {
        var headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
        return this.http.get(Constants.searchSpringURL + "/recommend/best-selling?siteId=" + Constants.searchSpringSiteId + url, { headers: headers });
    };
    // Get Recently Viewed Products searchSpring
    ProductService.prototype.getRecentlyViewedProducts = function () {
        return of(this.storageService.getItem('recentlyViewedProducts'));
    };
    // Get Global Search results searchSpring
    ProductService.prototype.getIntelliSuggestProducts = function (query, termCount, productCount) {
        var headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
        return this.http.get(Constants.searchSpringIntelliSuggestURL + "?siteId=" + Constants.searchSpringSiteId + "&query=" + query + "&termCount=" + termCount + "&productCount=" + productCount, { headers: headers });
    };
    ProductService.ngInjectableDef = i0.defineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.inject(i1.HttpClient), i0.inject(i2.StorageService)); }, token: ProductService, providedIn: "root" });
    return ProductService;
}());
export { ProductService };

<div class="w-5/6 xl:w-3/4 mx-auto">
    <div class="container container-padding mx-auto py-3 bg-grey-lighter my-12 pt-12">
        <div class="text-center">
            <div class="flex justify-center items-center">
                <img [src]="'./../../../../assets/img/error/error-icon.png'" alt="error-icon" appImageLazyLoad loading="lazy" />
                <h1 class="fa-4x md:fa-5x ml-4 font-black">410</h1>
            </div>
            <h2 class="text-3xl md:text-4xl mb-2 mt-3 md:mt-2 uppercase font-black">
                Page Not Found
            </h2>
            <p>
                The page you are looking for doesn’t exist or has been moved.
            </p>
            <a [routerLink]="['/home']" class="button button--fill md:w-1/2 xl:w-1/3 block mt-8 mb-8 md:mb-16 mx-auto">Back to Home Page</a>
        </div>
    </div>
</div>
<!--/error message-wrapper-->

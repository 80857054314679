// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  versionCheckURL: 'https://www.jdprodwebapp.com/version.json',
  // Domain URL
  domainURL: 'https://www.jdprodwebapp.com',
  // Search Spring Credentials Start
  searchSpringURL: 'https://k4wrjy.a.searchspring.io/api',
  searchSpringIntelliSuggestURL: 'https://k4wrjy.a.searchspring.io/api/suggest/query',
  searchSpringSiteId: 'k4wrjy',
  // Search Spring Credentials End

  // Server Credentials Start
  UserID: '4Ais-WL+WV3,4_wFPv8Vc#SQ7qzoN(tt',
  SiteID: 'F(qO0cINsp%0uD_.SDROmpijb0Ca%O@@',
  baseURL: 'https://api.jdprodwebapp.com/v1', // AWS development platform
  // Server Credentials End
  // Cognito Credentials Start

  // Cognito Credentials Start - AWS production platform
  cognito: {
    userPoolId: 'us-east-1_bUrxJf0Fh', // AWS Pool ID
    region: 'us-east-1', // AWS Region
    clientId: '1v9p3urev7c38lfm3fijbi2e54' // AWS Client ID
  },
  // Cognito Credentials End

  // Google Mesurement Id
  gaMasurementId: 'UA-88291404-1',
  // Google Mesurement Id

  // Google Site Id
  googleSiteId: '6Lcn1nscAAAAAHDuWvUVGbFCCw2AJW5G_7zZGNmz',
  // Google Site Id

  // Google Site Id
  googleApiId: 'AIzaSyCoobixwdQkeglC6-xK4ucYY6Q4og6Q46o',
  // Google Site Id

  // Paypal Credentials
  paypal: {
    payPalAccessTokenUrl: 'https://api.sandbox.paypal.com/v1/oauth2/token',
    paypalAPILoginID: '44AP9hu7',
    paypalTransactionKey: '6z8B78h6sVFS2wq9',
    payPalClientID: 'AV4AWxbmniuTlcFt_XbsujMW-KHJZKSyrLhe8odZA85zDj_TdIqkhMxIaEnlKqQjBWCkGJR9IHYdDUUe',
    payPalSecret: 'EIJ4xbWWZ6jXwkr0bhzHzNNVFSZEt5HOdMqUVxJguk2ISoaMK6axpo77uZBykYkZCM2RCE1AjzXbV99A',
    payPalSuccessUrl: 'https://my.server.com/success.html',
    payPalCancelUrl: 'https://my.server.com/cancel.html',
    payPalAPIUrlTest: 'https://api.sandbox.paypal.com',
    payPalAPIUrl: 'https://api.sandbox.paypal.com'
  },
  // Paypal Credentials

  // Apple Pay Credentials
  applePay: {
    // merchantIdentifier: 'FC8A545071C274A7BB95E9109D42D99E61510B11CF614E543A5874643FD38936',
    merchantIdentifier: 'merchant.com.jamestowndistributors.www',  // production MerchantID
    // merchantIdentifier: 'merchant.com.jamesownwebapp.www',  //sandbox MerchantID
    applePayOpaqueDataDescriptor: 'COMMON.APPLE.INAPP.PAYMENT',
    merchantCapabilities: {
      supportsCredit: 'supportsCredit',
      supportsDebit: 'supportsDebit',
      supports3DS: 'supports3DS'
    },
    supportedNetworks: {
      masterCard: 'masterCard',
      discover: 'discover',
      amex: 'amex',
      visa: 'visa'
    },
    supportedMethods: 'https://apple.com/apple-pay',
    displayName: 'Jamestown Distributors, LLC',
    initiative: 'web',
    initiativeContext: 'www.jamestowndistributors.com'
  },
  // Apple Pay Credentials
  // Payment gateway

  // pro-sitemap Credentials Start
  proSitemapApiKey: 'ps_gzvuXeX.KI2n7ddRy6EKWAJUf8hFHVR1KsBq0EPYa1vlg3W5UCwU6T',
  proSitemapSiteId: '4058640',
  proSitemapApiUrl: 'https://pro-sitemaps.com/api/',
  // pro-sitemap Credentials End

  // Bloomreach Credentials Start
  bloomreachApiUrl: 'https://us3-api.eng.bloomreach.com',
  bloomreachApiToken: 'd02aa968-5683-11ef-9280-1216db1cfbbe',
  // Bloomreach Credentials End
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error',  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { CheckoutResolveService } from './checkout/checkout-resolve.service';
import { BaseComponent } from './base/base.component';

const routes: Routes = [{
  path: '',
  component: BaseComponent,
  children: [
    {
      path: 'login',
      loadChildren: './login/login.module#LoginModule'
    },
    {
      path: 'home',
      loadChildren: './home-page/home-page.module#HomePageModule'
    },
    {
      path: 'page',
      loadChildren: './page/page.module#PageModule'
    },
    {
      path: 'product',
      loadChildren: './product/product.module#ProductModule'
    },
    // {
    //   path: 'userportal',
    //   loadChildren: './product/product.module#ProductModule'
    // },
    {
      path: 'cart',
      loadChildren: './cart/cart.module#CartModule'
    },
    {
      path: 'useraccount',
      loadChildren: './useraccount/useraccount.module#UseraccountModule'
    },
    {
      path: 'checkout',
      loadChildren: './checkout/checkout.module#CheckoutModule',
      resolve: {
        cartData: CheckoutResolveService
      }
    },
    {
      path: 'organization',
      loadChildren: './organization/organization.module#OrganizationModule'
    },
    {
      path: 'error',
      loadChildren: './error/error.module#ErrorModule'
    },
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: 'page-not-found',
      component: PageNotFoundComponent
    },
    {
      path: '**',
      component: PageNotFoundComponent
    }
  ]
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 105],
      useHash: false,
    })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

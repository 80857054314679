import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@angular/router";
var NotificationService = /** @class */ (function () {
    function NotificationService(toastr, router) {
        this.toastr = toastr;
        this.router = router;
        this.notify = new Subject();
    }
    NotificationService.prototype.showSuccess = function (message, title, disableAutoClose) {
        if (title === void 0) { title = 'Success'; }
        if (disableAutoClose === void 0) { disableAutoClose = false; }
        var successToaster = this.toastr.success(this.roleAlertTitleMessage(title, message, disableAutoClose), title);
        this.anchorTagAriaHiddenAndPlayPause(successToaster, disableAutoClose);
    };
    NotificationService.prototype.showError = function (message, title, disableAutoClose) {
        if (title === void 0) { title = 'Error'; }
        if (disableAutoClose === void 0) { disableAutoClose = false; }
        var successToaster = this.toastr.error(this.roleAlertTitleMessage(title, message, disableAutoClose), title);
        this.anchorTagAriaHiddenAndPlayPause(successToaster, disableAutoClose);
    };
    NotificationService.prototype.showInfo = function (message, title, disableAutoClose) {
        if (title === void 0) { title = 'Info'; }
        if (disableAutoClose === void 0) { disableAutoClose = false; }
        var successToaster = this.toastr.info(this.roleAlertTitleMessage(title, message, disableAutoClose), title);
        this.anchorTagAriaHiddenAndPlayPause(successToaster, disableAutoClose);
    };
    NotificationService.prototype.showWarning = function (message, title, disableAutoClose) {
        if (title === void 0) { title = 'Warning'; }
        if (disableAutoClose === void 0) { disableAutoClose = false; }
        var successToaster = this.toastr.warning(this.roleAlertTitleMessage(title, message, disableAutoClose), title);
        this.anchorTagAriaHiddenAndPlayPause(successToaster, disableAutoClose);
    };
    NotificationService.prototype.hide = function () {
        this.toastr.clear();
    };
    NotificationService.prototype.notifyLogin = function (event) {
        this.notify.next(true);
    };
    NotificationService.prototype.roleAlertTitleMessage = function (title, message, disableAutoClose) {
        var playPause = '';
        if (!disableAutoClose) {
            playPause = "<div  class=\"ngx-toastr-play-pause\"><i class=\"fa fa-pause play-pause-icon\" aria-hidden=\"true\"></i></div>";
        }
        return "<div class=\"ngx-toastr-title-with-message\" tabindex=\"0\" role=\"alert\"><span>" + title + "</span> " + message + " </div>" + playPause;
    };
    NotificationService.prototype.anchorTagAriaHiddenAndPlayPause = function (successToaster, disableAutoClose) {
        var _this = this;
        setTimeout(function () {
            var element = document.querySelector('.ngx-toastr-title-with-message');
            if (element) {
                element.querySelector('a').setAttribute('aria-hidden', 'true');
            }
        }, 100);
        setTimeout(function () {
            _this.toastTimeout = setTimeout(function () {
                if (!disableAutoClose) {
                    console.log('disableAutoClose- :', disableAutoClose);
                    _this.toastr.remove(successToaster.toastId);
                }
            }, 5000);
            if (!disableAutoClose) {
                var playPauseIcon_1 = document.querySelector('.ngx-toastr-play-pause .play-pause-icon');
                if (playPauseIcon_1) {
                    playPauseIcon_1.addEventListener('click', function () {
                        if (playPauseIcon_1.classList.contains('fa-pause')) {
                            clearTimeout(_this.toastTimeout);
                            playPauseIcon_1.classList.replace('fa-pause', 'fa-play');
                        }
                        else if (playPauseIcon_1.classList.contains('fa-play')) {
                            _this.toastTimeout = setTimeout(function () {
                                _this.toastr.remove(successToaster.toastId);
                            }, 5000);
                            playPauseIcon_1.classList.replace('fa-play', 'fa-pause');
                        }
                    });
                }
            }
            var updateBillingInformation = document.querySelector('.update-billing-information');
            if (updateBillingInformation) {
                updateBillingInformation.addEventListener('click', function () {
                    _this.router.navigate(['/checkout/billing'], { queryParams: { route: 'billing', isPaypalCheckout: false } });
                    _this.toastr.remove(successToaster.toastId);
                });
            }
            var updateShippingInformation = document.querySelector('.update-shipping-information');
            if (updateShippingInformation) {
                updateShippingInformation.addEventListener('click', function () {
                    _this.router.navigate(['/checkout/info-shipping'], { queryParams: { route: 'info-shipping' } });
                    _this.toastr.remove(successToaster.toastId);
                });
            }
        });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.ToastrService), i0.inject(i2.Router)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };

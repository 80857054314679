import { OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../shared/constants';
import { StorageService } from '../shared/services/storage.service';
import { CartService } from '../cart/cart.service';
import { NotificationService } from '../shared/services/notification.service';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { LoaderService } from '../shared/services/loader.service';
import { LoginService } from '../login/login.service';
import { GoogleAnalyticsService } from '../shared/services/google-analytics.service';
import { BingAnalyticsService } from '../shared/services/bing-analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/services/storage.service";
import * as i3 from "../shared/services/loader.service";
import * as i4 from "../login/login.service";
import * as i5 from "../cart/cart.service";
import * as i6 from "../shared/services/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../shared/services/google-analytics.service";
import * as i9 from "../shared/services/bing-analytics.service";
import * as i10 from "@angular/router";
var CheckoutService = /** @class */ (function () {
    function CheckoutService(http, storageService, loaderService, loginService, cartService, notificationService, document, googleAnalyticsService, rendererFactory, bingAnalyticsService, router) {
        var _this = this;
        this.http = http;
        this.storageService = storageService;
        this.loaderService = loaderService;
        this.loginService = loginService;
        this.cartService = cartService;
        this.notificationService = notificationService;
        this.document = document;
        this.googleAnalyticsService = googleAnalyticsService;
        this.rendererFactory = rendererFactory;
        this.bingAnalyticsService = bingAnalyticsService;
        this.router = router;
        this.basicAuth = btoa(Constants.paypal.payPalClientID + ":" + Constants.paypal.payPalSecret);
        this.zipCodeErrorMessage = new BehaviorSubject('');
        this.orderSummaryData = new BehaviorSubject({});
        this.orderSummaryData$ = this.orderSummaryData.asObservable();
        this.customerOrderData = new BehaviorSubject({});
        this.customerOrderData$ = this.customerOrderData.asObservable();
        this.orderObj = new BehaviorSubject({});
        this.orderObj$ = this.orderObj.asObservable();
        this.updateCart = new BehaviorSubject(false);
        this.updateCart$ = this.updateCart.asObservable();
        this.renderer2 = rendererFactory.createRenderer(null, null);
        this.userSub = this.loginService.user.subscribe(function (user) {
            if (!!user) {
                _this.isLoggedIn = true;
            }
            else {
                _this.isLoggedIn = false;
            }
        });
    }
    CheckoutService.prototype.setErrorMessage = function (message) {
        this.zipCodeErrorMessage.next(message);
    };
    CheckoutService.prototype.getErrorMessage = function () {
        return this.zipCodeErrorMessage.asObservable();
    };
    CheckoutService.prototype.pushOrderSummary = function (orderSummary) {
        this.orderSummaryData.next(orderSummary);
    };
    CheckoutService.prototype.pushCustomerOrderDetails = function (customerOrder) {
        this.customerOrderData.next(customerOrder);
    };
    CheckoutService.prototype.pushOrderObjData = function (orderObj) {
        this.orderObj.next(orderObj);
    };
    CheckoutService.prototype.updateCartAfterLogin = function (isUpdate) {
        this.updateCart.next(isUpdate);
    };
    CheckoutService.prototype.getOrderSummary = function () {
        var orderSummary = this.orderSummaryData.getValue();
        if (orderSummary.length) {
            return orderSummary;
        }
        orderSummary = this.storageService.sessionGetItem('orderSummary');
        if (orderSummary) {
            this.pushOrderSummary(orderSummary);
        }
        return orderSummary;
    };
    CheckoutService.prototype.getCustomerOrderDetails = function () {
        var customerOrder = this.customerOrderData.getValue();
        if (customerOrder.length) {
            return customerOrder;
        }
        customerOrder = this.storageService.sessionGetItem('customerOrder');
        if (customerOrder) {
            this.pushOrderSummary(customerOrder);
        }
        return customerOrder;
    };
    CheckoutService.prototype.buildCustomerOrderSummary = function (orderSummary, subTotal, existingShippingMethod) {
        if (existingShippingMethod === void 0) { existingShippingMethod = orderSummary.shippingMethod; }
        if (orderSummary && orderSummary.content) {
            orderSummary.content.forEach(function (element) {
                // Calculate Transit Days
                // Edit 30/12/2019 - Added earliestDeliveryDate & latestDeliveryDate, removed logic of transitDays
                element.shipDate1 = element.earliestDeliveryDate;
                element.shipDate2 = element.latestDeliveryDate;
                if (existingShippingMethod && existingShippingMethod === element.shippingMethod) {
                    if (existingShippingMethod === 'In-store Pickup') {
                        orderSummary.estimatedTotal = (+subTotal -
                            +element.hazmatHandlingFee - +element.shippingTotal).toFixed(2);
                    }
                    else {
                        // Apply Shipping charges as per Promo Code
                        if (orderSummary.promoCodeObj && orderSummary.promoCodeObj.shipping && (orderSummary.promoCodeObj.shipping.total <= element.shippingTotal)) {
                            orderSummary.promocodeSavings += orderSummary.shippingTotal - (+orderSummary.promoCodeObj.shipping.total);
                            orderSummary.shippingTotal = (+orderSummary.promoCodeObj.shipping.total).toFixed(2);
                        }
                        else {
                            orderSummary.shippingTotal = +(element.shippingTotal ? element.shippingTotal.toFixed(2) : 0);
                        }
                        orderSummary.estimatedTotal = (+subTotal +
                            +element.hazmatHandlingFee + +orderSummary.shippingTotal).toFixed(2);
                    }
                    orderSummary.hazmatHandlingFee = +element.hazmatHandlingFee.toFixed(2);
                    orderSummary.rushOrderFee = +element.rushOrderFee.toFixed(2);
                    orderSummary.rushOrderFeePrice = +element.rushOrderFeePrice.toFixed(2);
                    orderSummary.shippingMethod = element.shippingMethod;
                    orderSummary.earliestDeliveryDate = element.earliestDeliveryDate;
                    orderSummary.latestDeliveryDate = element.latestDeliveryDate;
                }
            });
        }
        orderSummary.orderSubtotal = +subTotal.toFixed(2);
        orderSummary.salesTax = (orderSummary && orderSummary.salesTax) ? +orderSummary.salesTax.toFixed(2) : 0;
        return orderSummary;
    };
    CheckoutService.prototype.getRushOrderFees = function (url) {
        return this.http.get(Constants.baseURL + "/shippingMethod" + url);
    };
    CheckoutService.prototype.getRushOrderFeesPromise = function (url) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            return resolve(_this.http.get(Constants.baseURL + "/shippingMethod" + url).toPromise());
        });
    };
    CheckoutService.prototype.applyPromoCode = function (url) {
        return this.http.get(Constants.baseURL + "/promocode/" + url);
    };
    CheckoutService.prototype.removePromoCode = function (url) {
        return this.http.get(Constants.baseURL + "/promocode/" + url);
    };
    CheckoutService.prototype.placeOrder = function (body, isLoggedIn) {
        if (isLoggedIn) {
            return this.http.post(Constants.baseURL + "/accounts/account/customer/orders", body);
        }
        else {
            return this.http.post(Constants.baseURL + "/orders", body);
        }
    };
    CheckoutService.prototype.getSalesTax = function (url) {
        return this.http.get(Constants.baseURL + "/" + url);
    };
    CheckoutService.prototype.getSalesTaxPromise = function (url) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            resolve(_this.http.get(Constants.baseURL + "/" + url));
        });
    };
    CheckoutService.prototype.paypalPatchOrder = function (body, orderId) {
        var _this = this;
        return this.http.post(Constants.paypal.payPalAPIUrlTest + "/v1/oauth2/token", 'grant_type=client_credentials', {
            headers: new HttpHeaders({
                Accept: "application/json",
                Authorization: "Basic " + this.basicAuth
            })
        }).pipe(mergeMap(function (data) {
            return _this.http.patch(Constants.paypal.payPalAPIUrlTest + "/v2/checkout/orders/" + orderId, body, {
                headers: new HttpHeaders({
                    Accept: "application/json",
                    Authorization: "Bearer " + data.access_token
                })
            });
        }));
    };
    CheckoutService.prototype.paypalGetOrder = function (orderId) {
        var _this = this;
        return this.http.post(Constants.paypal.payPalAPIUrlTest + "/v1/oauth2/token", 'grant_type=client_credentials', {
            headers: new HttpHeaders({
                Accept: "application/json",
                Authorization: "Basic " + this.basicAuth
            })
        }).pipe(mergeMap(function (data) {
            return _this.http.get(Constants.paypal.payPalAPIUrlTest + "/v2/checkout/orders/" + orderId, {
                headers: new HttpHeaders({
                    Accept: "application/json",
                    Authorization: "Bearer " + data.access_token
                })
            });
        }));
    };
    CheckoutService.prototype.paypalCheckout = function (url, body) {
        var _this = this;
        return this.http.post(Constants.paypal.payPalAPIUrlTest + "/v1/oauth2/token", 'grant_type=client_credentials', {
            headers: new HttpHeaders({
                Accept: "application/json",
                Authorization: "Basic " + this.basicAuth
            })
        }).pipe(mergeMap(function (data) {
            return _this.http.post(url, body, {
                headers: new HttpHeaders({
                    Accept: "application/json",
                    Authorization: "Bearer " + data.access_token
                })
            });
        }));
    };
    CheckoutService.prototype.applePaypaymentSessionValidate = function (url, body) {
        return this.http.post(Constants.baseURL + "/applepayValidate", body);
    };
    CheckoutService.prototype.getOrderDetails = function (id) {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/orders/" + id);
    };
    CheckoutService.prototype.createOrderObj = function (orderObj, customerOrderData) {
        var _this = this;
        // Build SKUS Object for Order POST endpoint
        this.loaderService.show();
        orderObj.skus = [];
        customerOrderData.skus.forEach(function (element) {
            var skuData = {
                id: element.id,
                sellPrice: element.sellPrice,
                quantity: element.quantity,
                uom: element.uom,
                uomQty: element.uomQty
            };
            orderObj.skus.push(skuData);
        });
        // Save order into JD API
        var orderData = {};
        Object.assign(orderData, orderObj);
        try {
            window.grecaptcha.enterprise.ready(function () {
                window.grecaptcha.enterprise.execute(Constants.googleAnalytics.siteId, { action: 'CHECKOUT' })
                    .then(function (token) {
                    orderData.validationToken = token;
                    orderData.validationAction = 'CHECKOUT';
                    _this.placeOrder(orderData, _this.isLoggedIn).subscribe(function (response) {
                        _this.loaderService.show();
                        var cartId = _this.storageService.getItem('cartId');
                        // Update Google Analytics with Checkout Option Data
                        var event = {
                            url: '/checkout/order-confirmed'
                        };
                        _this.googleAnalyticsService.emitCheckoutOptions(event, response);
                        // Update Google Analytics with Purchased Event Tracking Data
                        _this.googleAnalyticsService.emitPurchaseEventTracking(Constants.googleAnalytics.eventTags.purchase, response);
                        _this.bingAnalyticsService.orderTracker(response);
                        _this.deleteSKUSformCart(_this.isLoggedIn, customerOrderData, cartId, response);
                    }, function (error) {
                        _this.loaderService.hide();
                        if (customerOrderData.paypalPayments) {
                            _this.abortPaypalTransaction(customerOrderData.paypalPayments).subscribe(function (res) {
                                if (res.status === 'COMPLETED') {
                                    _this.storageService.sessionRemoveItem('orderObj');
                                    _this.pushOrderObjData(null);
                                    _this.notificationService.showError(error + ', Payment refunded to source.', 'Error', true);
                                    _this.router.navigate(['/checkout/info-shipping'], { queryParams: { route: 'info-shipping', isPaypalCheckout: false } });
                                }
                            }, function (err) {
                                _this.notificationService.showError(err, 'Error', true);
                            });
                        }
                        else {
                            var htmlTag = "<strong class=\"checkout-page-error\">" + (error.length > 350 ? error.substring(0, 350).trim() + '...' : error) + "</strong>";
                            if (error.includes('300')) {
                                htmlTag += "<a href=\"cart\" class=\"place-order-toater-button bg-transparent font-semibold py-2 px-4 border-2 border-white rounded-full no-underline button\">Review Cart</a>";
                            }
                            else if (error.includes('200')) {
                                htmlTag += "<a class=\"place-order-toater-button update-shipping-information bg-transparent font-semibold py-2 px-4 border-2 border-white button\">Update Shipping Information</a>";
                            }
                            else if (error.includes('100')) {
                                htmlTag += "<a class=\"place-order-toater-button update-billing-information bg-transparent font-semibold py-2 px-4 border-2 border-white button\">Update Billing Information</a>";
                            }
                            _this.notificationService.showError(htmlTag, 'Error', true);
                        }
                    });
                }), function (err) {
                    _this.loaderService.hide();
                };
            });
        }
        catch (err) {
            this.loaderService.hide();
        }
    };
    CheckoutService.prototype.abortPaypalTransaction = function (paypalPaymentsInfo) {
        var _this = this;
        return this.http.post(Constants.paypal.payPalAPIUrlTest + "/v1/oauth2/token", 'grant_type=client_credentials', {
            headers: new HttpHeaders({
                Accept: "application/json",
                Authorization: "Basic " + this.basicAuth
            })
        }).pipe(mergeMap(function (data) {
            var refundUrl = paypalPaymentsInfo.captures[0].links[1].href;
            return _this.http.post(refundUrl, {}, {
                headers: new HttpHeaders({
                    Accept: "application/json",
                    Authorization: "Bearer " + data.access_token
                })
            });
        }));
    };
    CheckoutService.prototype.deleteSKUSformCart = function (isLoggedIn, customerOrderData, cartId, response) {
        this.clearCart(isLoggedIn, customerOrderData);
        this.storageService.sessionSetItem('orderedData', response);
        this.storageService.sessionRemoveItem('orderSummary');
        this.storageService.sessionRemoveItem('customerOrder');
        this.storageService.sessionRemoveItem('orderObj');
        this.pushOrderSummary(null);
        this.pushCustomerOrderDetails(null);
        this.pushOrderObjData(null);
        this.storageService.removeItem('poBox');
        this.loaderService.hide();
        this.notificationService.showSuccess('Order placed successfully');
        this.router.navigate(['/checkout/order-confirmed/', response.id]);
    };
    CheckoutService.prototype.clearCart = function (isLoggedIn, cartData) {
        var _this = this;
        var cartId = this.storageService.getItem('cartId');
        if (isLoggedIn) {
            cartData.skus.forEach(function (element) {
                _this.cartService.deleteShoppingCartSkusById(cartId, element.id).subscribe(function (res) {
                }, function (err) {
                    _this.notificationService.showError(err, 'Error', true);
                });
            });
        }
        else {
            cartData.skus.forEach(function (element) {
                _this.cartService.deleteShoppingCartSkusByIdWithoutAccount(cartId, element.id).subscribe(function (res) {
                }, function (err) {
                    _this.notificationService.showError(err, 'Error', true);
                });
            });
        }
        this.cartService.updateCartValue(0.00);
        this.storageService.setItem('subTotal', '0');
    };
    CheckoutService.prototype.ngOnDestroy = function () {
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    };
    CheckoutService.ngInjectableDef = i0.defineInjectable({ factory: function CheckoutService_Factory() { return new CheckoutService(i0.inject(i1.HttpClient), i0.inject(i2.StorageService), i0.inject(i3.LoaderService), i0.inject(i4.LoginService), i0.inject(i5.CartService), i0.inject(i6.NotificationService), i0.inject(i7.DOCUMENT), i0.inject(i8.GoogleAnalyticsService), i0.inject(i0.RendererFactory2), i0.inject(i9.BingAnalyticsService), i0.inject(i10.Router)); }, token: CheckoutService, providedIn: "root" });
    return CheckoutService;
}());
export { CheckoutService };

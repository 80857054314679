import { environment } from './../../environments/environment';

export class Constants {

    // Domain URL
    public static domainURL: string = environment.domainURL;

    // Search Spring Credentials Starts
    public static searchSpringURL: string = environment.searchSpringURL;
    public static autocompleteSearchSpringURL: string = environment.searchSpringURL;
    public static searchSpringIntelliSuggestURL: string = environment.searchSpringIntelliSuggestURL;
    public static searchSpringSiteId: string = environment.searchSpringSiteId;
    public static searchSpringResultsLimit: string = '10';
    // Search Spring Credentials End

    // Server Credentials Start
    public static UserID = environment.UserID;
    public static SiteID = environment.SiteID;
    public static baseURL: string = environment.baseURL;
    // Server Credentials End

    // pro-sitemap Credentials Start
    public static proSitemapApiKey = environment.proSitemapApiKey;
    public static proSitemapSiteId = environment.proSitemapSiteId;
    public static proSitemapApiUrl: string = environment.proSitemapApiUrl;
    // pro-sitemap Credentials End

    // Bloomreach Credentials Start
    public static bloomreachApiUrl = environment.bloomreachApiUrl;
    public static bloomreachApiToken = environment.bloomreachApiToken;
    // Bloomreach Credentials End

    // Cognito Credentials Start
    public static cognito = environment.cognito;
    // Cognito Credentials End

    // External URLs Start
    public static totalBoatURL = 'http://www.totalboat.com';
    public static privacyPolicy = 'https://support.jamestowndistributors.com/hc/en-us/sections/360008318454-Privacy-Policy-FAQs';
    public static termsPolicy = 'https://support.jamestowndistributors.com/hc/en-us/categories/360003089913-JD-Com-Info-and-Policy-Support';
    public static returnPolicy = 'https://support.jamestowndistributors.com/hc/en-us/sections/360008415273-Order-Cancellation-Returns-FAQs';
    public static helpCenter = 'https://support.jamestowndistributors.com/hc/en-us';
    public static jobsLink = `https://recruiting.paylocity.com/recruiting/jobs/All/d5ecdaba-4cfc-4157-8be1-bc160dd54d27/Jamestown-Distributors`;
    // Zendesk Start
    public static productSupportURL = 'https://support.jamestowndistributors.com/hc/en-us/categories/360001134314-Product-Support';
    public static zendeskGuide = 'http://support.jamestowndistributors.com';
    // Zendesk End

    public static projectGuidesURL = 'https://guides.jamestowndistributors.com';
    public static jdProURL = 'https://pro.jamestowndistributors.com';
    // External URLs End

    public static pageSize = 16;
    public static wishlistPageSize = 10;
    public static orderlistPageSize = 10;
    public static youtubeURL = 'https://www.youtube.com/embed/';
    public static youtubeThumbnailURL = 'https://img.youtube.com/vi/{videoId}/hqdefault.jpg';
    public static inStock = 'https://schema.org/InStock';
    public static outStock = 'https://schema.org/OutOfStock';

    // RegEx Start
    public static passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\\]\\[\\)\\(\\-]).{8,}');
    // public static passwordPattern = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$');
    public static phonePattern = new RegExp('^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$');
    public static zipPattern = new RegExp('^[0-9]*$');
    public static emailPattern = '[a-zA-Z0-9.-_%+-]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z]{2,63}';
    public static cvvPattern = '^([0-9]{3,4})$';

    public static visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    public static mastercardRegEx = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
    public static amexpRegEx = /^(?:3[47][0-9]{13})$/;
    public static discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    // RegEx Start

    // Social Media URLs Start
    public static twitterLink = 'https://twitter.com/JDTotalBoat';
    public static youtubeLink = 'https://www.youtube.com/user/JamestownTV';
    public static facebookLink = 'https://www.facebook.com/JamestownDistributors/';
    public static instagramLink = 'https://www.instagram.com/totalboat/';
    // Social Media URLs End

    public static Win32Platform = 'Win32';
    public static homePageTitle = 'Jamestown Distributors: Boat Building Repair and Project Supplies';
    public static homePageDescription = 'Marine supplies, boat building, repair and woodworking products including paint, varnish, epoxy, hardware, and more. Tools and inspiration for craftsmen since 1977';
    public static resourceNotFound = 'Resource not found';
    public static hazmatWarningMessage = 'Hazmat and ORMD products can not be shipped to a PO Box. Please choose a different shipping address';
    public static hazardousShipmentWarningMessage = `Sorry, we're unable to ship hazardous or ORMD items to your address. Please choose a different shipping address.`;
    public static expiryDateValidationMessage = 'Exp. Date is invalid. Please ensure that the card has not expired and the date format is MM/YYYY';
    public static loaderImagePath = './../../../assets/img/image-loading.jpg';
    public static noImagePath = './../../../assets/img/image_not_available.png';

    // State List
    public static stateList = [
        { stateName: 'Alabama', stateValue: 'AL' },
        { stateName: 'Alaska', stateValue: 'AK' },
        { stateName: 'Arizona', stateValue: 'AZ' },
        { stateName: 'Arkansas', stateValue: 'AR' },
        { stateName: 'California', stateValue: 'CA' },
        { stateName: 'Colorado', stateValue: 'CO' },
        { stateName: 'Connecticut', stateValue: 'CT' },
        { stateName: 'Delaware', stateValue: 'DE' },
        { stateName: 'District of Columbia', stateValue: 'DC' },
        { stateName: 'Florida', stateValue: 'FL' },
        { stateName: 'Georgia', stateValue: 'GA' },
        { stateName: 'Hawaii', stateValue: 'HI' },
        { stateName: 'Idaho', stateValue: 'ID' },
        { stateName: 'Illinois', stateValue: 'IL' },
        { stateName: 'Indiana', stateValue: 'IN' },
        { stateName: 'Iowa', stateValue: 'IA' },
        { stateName: 'Kansas', stateValue: 'KS' },
        { stateName: 'Kentucky', stateValue: 'KY' },
        { stateName: 'Louisiana', stateValue: 'LA' },
        { stateName: 'Maine', stateValue: 'ME' },
        { stateName: 'Maryland', stateValue: 'MD' },
        { stateName: 'Massachusetts', stateValue: 'MA' },
        { stateName: 'Michigan', stateValue: 'MI' },
        { stateName: 'Minnesota', stateValue: 'MN' },
        { stateName: 'Mississippi', stateValue: 'MS' },
        { stateName: 'Missouri', stateValue: 'MO' },
        { stateName: 'Montana', stateValue: 'MT' },
        { stateName: 'Nebraska', stateValue: 'NE' },
        { stateName: 'Nevada', stateValue: 'NV' },
        { stateName: 'New Hampshire', stateValue: 'NH' },
        { stateName: 'New Jersey', stateValue: 'NJ' },
        { stateName: 'New Mexico', stateValue: 'NM' },
        { stateName: 'New York', stateValue: 'NY' },
        { stateName: 'North Carolina', stateValue: 'NC' },
        { stateName: 'North Dakota', stateValue: 'ND' },
        { stateName: 'Ohio', stateValue: 'OH' },
        { stateName: 'Oklahoma', stateValue: 'OK' },
        { stateName: 'Oregon', stateValue: 'OR' },
        { stateName: 'Pennsylvania', stateValue: 'PA' },
        { stateName: 'Rhode Island', stateValue: 'RI' },
        { stateName: 'South Carolina', stateValue: 'SC' },
        { stateName: 'South Dakota', stateValue: 'SD' },
        { stateName: 'Tennessee', stateValue: 'TN' },
        { stateName: 'Texas', stateValue: 'TX' },
        { stateName: 'Utah', stateValue: 'UT' },
        { stateName: 'Vermont', stateValue: 'VT' },
        { stateName: 'Virginia', stateValue: 'VA' },
        { stateName: 'Washington', stateValue: 'WA' },
        { stateName: 'West Virginia', stateValue: 'WV' },
        { stateName: 'Wisconsin', stateValue: 'WI' },
        { stateName: 'Wyoming', stateValue: 'WY' },
    ];
    // State List

    // Country
    public static countryUSA = 'USA';
    public static countryUS = 'US';
    public static currencyUSD = 'USD';
    // Country

    // Credit Card constants
    public static visa = { key: 'VI', value: 'Visa' };
    public static americanExpress = { key: 'AX', value: 'American Express' };
    public static masterCard = { key: 'MC', value: 'MasterCard' };
    public static discoverCard = { key: 'DS', value: 'DiscoverCard' };
    // Credit Card constants

    // Payment Transaction Values
    public static paymentMode = {
        eclipseCreditCard: 'Eclipse_CreditCard',
        authorizeNetCreditCard: 'Authorize.Net_CreditCard',
        payPal: 'PayPal',
        applePay: 'Apple_Pay',
        amazonPay: 'Amazon_Pay'
    };
    // Payment Transaction Values

    // Payment Transaction Type
    public static trasantionType = {
        authCaptureTransaction: 'authCaptureTransaction',
        authOnlyTransaction: 'authOnlyTransaction',
        priorAuthCaptureTransaction: 'priorAuthCaptureTransaction',
        captureOnlyTransaction: 'captureOnlyTransaction',
        voidTransaction: 'voidTransaction',
        refundTransaction: 'refundTransaction'
    };
    // Payment Transaction Type

    // Paypal Credentials
    public static paypal = environment.paypal;
    // Paypal Credentials

    // Apple Pay Credentials
    public static applePay = environment.applePay;
    // Apple Pay Credentials
    // Payment gateway

    public static viewAllGuides = '';

    public static googleAnalytics = {
        eventTags: {
            pageNavigation: 'page navigation',
            applicationLoad: 'application load',
            purchase: 'purchase',
            beginCheckout: 'begin_checkout',
            checkoutProgress: 'checkout_progress',
            addToCart: 'add_to_cart',
            removeFromCart: 'remove_from_cart'
        },
        gaMasurementId: environment.gaMasurementId,
        siteId: environment.googleSiteId
    };

    public static splitOrderMessage = 'We will wait to ship your order until all items are available.';
    public static hazmatProductInfo = `A small hazardous item handling fee will be added to your order. This fee is applied only once per order, and not per hazardous item.`;
    public static shippingStatus = {
        shipped: {
            status: 'shipped',
            message: 'Shipped - Your order was shipped on'
        },
        pending: {
            status: 'pending',
            message: 'Pending - Your order is being prepared for shipment'
        },
        backorder: {
            status: 'backorder',
            message: ''
        }
    };
    public static JDFakeException = 'JDFakeException to prevent sending code';
}
